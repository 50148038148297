/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";


@import "./bootswatch/variables";

$line-1: $green-700;
$line-2: $green-600;
$line-4: $green-500;
$line-8: $green-400;
$totals: #673AB7;
$points: #2211B7;
$nopoints: #bbb;

@import "../../node_modules/bootstrap/scss/variables";

$custom-theme-colors: (
  "totals": $totals,
  "points": $points,
  "nopoints": $nopoints,
  "line-1": $line-1,
  "line-2": $line-2,
  "line-4": $line-4,
  "line-8": $line-8,
  "zero": #eee,
  "positive": $gray-500,
  "negative": $red-100,
);
$custom-theme-border-colors: (
  "zero": #ddd,
  "positive": $blue,
  "negative": $red-200,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import "../../node_modules/bootstrap/scss/maps";

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
// @import "images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
// @import "transitions";
// @import "dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
// @import "accordion";
// @import "breadcrumb";
// @import "pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
// @import "toasts";
@import "../../node_modules/bootstrap/scss/modal";
// @import "tooltip";
@import "../../node_modules/bootstrap/scss/popover";
// @import "carousel";
// @import "spinners";
// @import "offcanvas";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack