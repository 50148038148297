@import './mixins';

$enable-responsive-font-sizes: true !default;
@import "./bootstrap-custom-components"; // "~bootstrap/scss/bootstrap";
@import "./bootswatch/bootswatch";

// $custom-theme-colors: (
//   "totals": #673AB7,
//   "points": pink
// );

// $theme-colors: map-merge($custom-theme-colors, $theme-colors);

.current-user {
  @extend .text-primary !optional;
  @extend .font-weight-bold !optional;
}

.text-gold {
  color: gold;
}
.text-silver {
  color: silver;
}

a {
  color: inherit;
}

hr {
  border-top: 1px solid rgba(136, 136, 136, 0.30);
}

.table th {
  border-top-width: 0px;
}

html {
  position: relative;
}

app-root {
  display: flex;
  min-height: 100vh;
  height: 100vh;
  flex-direction: column;
}

.content-wrapper {
  flex: 1 0 auto;
}

footer {
  flex: 1 0 auto;
}

td .btn.btn-sm,
th .btn.btn-sm,
td .list-group-item {
  padding-top: 0px;
  padding-bottom: 0px;
}

.input-group-date {
  width: 185px;
}

input.is-valid,
textarea.is-valid {
  border-left: 5px solid #{$success};
}

input.is-invalid,
textarea.is-invalid {
  border-left: 5px solid #{$danger};
}

input.is-warning {
  border-left: 5px solid #{$warning};
}

.badge-reserve {
  vertical-align: text-top;
  background-color: #98978b;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

span.badge-rank {
  background-color: inherit;
  color: inherit;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  font-size: 100%;
}

.modal-content-info .modal-content {
  border: 5px;
  @extend .border-info;
}

.warning-modal .modal-content {
  border: 5px;
  @extend .bg-warning;
  @extend .text-white;
}

.info-modal .modal-content {
  border: 5px;
  @extend .bg-info;
  @extend .text-white;
}

.info-modal .list-group-item {
  @extend .bg-info;
  @extend .text-white;
  @extend .border-white;
}

// .line-1 {
//   background-color: $gray-700;
// }
// .line-2 {
//   background-color: $gray-600;
// }
// .line-4 {
//   background-color: $gray-500;
// }
// .line-8 {
//   background-color: $gray-400;
// }

// .color-line-1 {
//   color: $gray-700;
// }

// .color-line-2 {
//   color: $gray-600;
// }

// .color-line-4 {
//   color: $gray-500;
// }

// .color-line-8 {
//   color: $gray-400;
// }

// .bg-color-line-1 {
//   background-color: $gray-700;
// }

// .bg-color-line-2 {
//   background-color: $gray-600;
// }

// .bg-color-line-4 {
//   background-color: $gray-500;
// }

// .bg-color-line-8 {
//   background-color: $gray-400;
// }

// .border-line-1 {
//   border-color: $gray-700;
// }

// .border-line-2 {
//   border-color: $gray-600;
// }

// .border-line-4 {
//   border-color: $gray-500;
// }

// .border-line-8 {
//   border-color: $gray-400;
// }

// table.border-line-1 td{
//   @extend .border-line-1;    
// }

// table.border-line-2 td{
//   @extend .border-line-2;
// }

// table.border-line-4 td{
//   @extend .border-line-4;
// }

// table.border-line-8 td{
//   @extend .border-line-8;
// }

/*
.progress {
  background-color:#{$body-bg};
}

.progress__value {
  stroke: #{$info};
}
.progress__meter {
  stroke: #{$body-bg};
}*/

.pointer {
  cursor: pointer;
}

ngb-alert {
  width: 100%;
}

.q-w-1 {
  border-color: #298F00 !important;
}

.q-w-2 {
  border-color: #84CF96 !important;
}

.q-w-3 {
  border-color: #0588BC !important;
}

.q-w-4 {
  border-color: #00578A !important;
}

.q-w-5 {
  border-color: $green-600  !important;
}

.q-l-5 {
  border-color: $green-600  !important;
}

.q-l-4 {
  border-color: #FFFF66 !important;
}

.q-l-3 {
  border-color: #FFCC00 !important;
}

.q-l-2 {
  border-color: #FF9900 !important;
}

.q-l-1 {
  border-color: #FF0000 !important;
}

.q-partial {
  border-style: dashed !important;
}

.q-w-1-double-partial {
  border-left-color: #298F00 !important;
  border-top-color: #298F00 !important;
}

.q-w-2-double-partial {
  border-left-color: #84CF96 !important;
  border-top-color: #84CF96 !important;
}

.q-w-3-double-partial {
  border-left-color: #0588BC !important;
  border-top-color: #0588BC !important;
}

.q-w-4-double-partial {
  border-left-color: #00578A !important;
  border-top-color: #00578A !important;
}

.q-w-5-double-partial {
  border-left-color: $green-600  !important;
  border-top-color: $green-600  !important;
}

.q-l-5-double-partial {
  border-right-color: $green-600  !important;
  border-bottom-color: $green-600  !important;
}

.q-l-4-double-partial {
  border-right-color: #FFFF66 !important;
  border-bottom-color: #FFFF66 !important;
}

.q-l-3-double-partial {
  border-right-color: #FFCC00 !important;
  border-bottom-color: #FFCC00 !important;
}

.q-l-2-double-partial {
  border-right-color: #FF9900 !important;
  border-bottom-color: #FF9900 !important;
}

.q-l-1-double-partial {
  border-right-color: #FF0000 !important;
  border-bottom-color: #FF0000 !important;
}

span.bg-rank {
  background-color: inherit;
  color: inherit;
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

ngb-datepicker-month {
  background-color: white;
}